// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationPoliciesSelect-module__cBIsHozmkXiJYwoAPJgL{width:150px !important}`, "",{"version":3,"sources":["webpack://./containers/AddResponders/parts/NotificationPoliciesSelect/NotificationPoliciesSelect.module.scss"],"names":[],"mappings":"AAAA,yDACE,sBAAA","sourcesContent":[".select {\n  width: 150px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `NotificationPoliciesSelect-module__cBIsHozmkXiJYwoAPJgL`
};
export default ___CSS_LOADER_EXPORT___;
