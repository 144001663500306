// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiTokenForm-module__jhqVcRIp70t7tDosk894 {
  width: 100%;
  display: flex;
}

.ApiTokenForm-module__U7RiEe0NAklz2FBjGy6g {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ApiTokenForm-module__epG_wWRW0NWSdhox5QKO {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ApiTokenForm-module__j7kxwuHfpqTndinZ91t4 {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./containers/ApiTokenSettings/ApiTokenForm.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".token__inputContainer {\n  width: 100%;\n  display: flex;\n}\n\n.token__input {\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n}\n\n.token__copyButton {\n  border-top-left-radius: 0;\n  border-bottom-left-radius: 0;\n}\n\n.field {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"token__inputContainer": `ApiTokenForm-module__jhqVcRIp70t7tDosk894`,
	"token__input": `ApiTokenForm-module__U7RiEe0NAklz2FBjGy6g`,
	"token__copyButton": `ApiTokenForm-module__epG_wWRW0NWSdhox5QKO`,
	"field": `ApiTokenForm-module__j7kxwuHfpqTndinZ91t4`
};
export default ___CSS_LOADER_EXPORT___;
