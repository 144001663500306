// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloudPage-module__AIOlSCyixSBc6YIw_txb {
  width: 70%;
  min-width: 1100px;
  padding: 24px;
}

.CloudPage-module__ZgInOf9tMA5FYzm5LbH_ {
  color: var(--warning-text-color);
}

.CloudPage-module__qfkpa2KU7Fg96bCX2Dg2 {
  color: var(--success-text-color);
}

.CloudPage-module__PQnt3SXXqM2XW5Wl2W87 {
  color: var(--error-text-color);
}

.CloudPage-module__Sp1YobfMI6WK7PEseghO {
  margin-top: 24px;
  width: 100%;
}

.CloudPage-module___pP8cCEsGYDmQqp0yVeK {
  height: 32px;
}

.CloudPage-module__SrVsbBbLV2sgQeo04ut7 {
  margin-top: 24px;
}

.CloudPage-module___1R89zqqHCjyXRxhZoqh {
  color: #f55f3e;
}

.CloudPage-module__l9wkFk4DyPEJPARkaPUQ {
  color: var(--secondary-text-color);
}

.CloudPage-module__EZE65Vrgp_xaPfnHWd6Q {
  display: inline-block;
  white-space: break-spaces;
  line-height: 20px;
  color: var(--error-text-color);
}

.CloudPage-module__EZE65Vrgp_xaPfnHWd6Q svg {
  vertical-align: middle;
}

.CloudPage-module__oPfGpZf6ki1J9ZWBCgMZ {
  color: var(--secondary-text-color);
  margin-right: 8px;
}

.CloudPage-module__jR6EOGKRihE26SqnzxoI {
  margin-top: 24px;
}

.CloudPage-module__R1JbEk62fA77lzsE40ZU {
  margin-bottom: var(--title-marginBottom);
}

.CloudPage-module__rbtLDtgknaKSwCgru8nU {
  float: right;
}
`, "",{"version":3,"sources":["webpack://./pages/settings/tabs/Cloud/CloudPage.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,YAAY;AACd","sourcesContent":[".info-block {\n  width: 70%;\n  min-width: 1100px;\n  padding: 24px;\n}\n\n.warning-message {\n  color: var(--warning-text-color);\n}\n\n.success-message {\n  color: var(--success-text-color);\n}\n\n.error-message {\n  color: var(--error-text-color);\n}\n\n.user-table {\n  margin-top: 24px;\n  width: 100%;\n}\n\n.user-row {\n  height: 32px;\n}\n\n.heartbit-button {\n  margin-top: 24px;\n}\n\n.cloud-oncall-name {\n  color: #f55f3e;\n}\n\n.block-icon {\n  color: var(--secondary-text-color);\n}\n\n.error-icon {\n  display: inline-block;\n  white-space: break-spaces;\n  line-height: 20px;\n  color: var(--error-text-color);\n}\n\n.error-icon svg {\n  vertical-align: middle;\n}\n\n.heart-icon {\n  color: var(--secondary-text-color);\n  margin-right: 8px;\n}\n\n.block-button {\n  margin-top: 24px;\n}\n\n.table-title {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.table-button {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-block": `CloudPage-module__AIOlSCyixSBc6YIw_txb`,
	"warning-message": `CloudPage-module__ZgInOf9tMA5FYzm5LbH_`,
	"success-message": `CloudPage-module__qfkpa2KU7Fg96bCX2Dg2`,
	"error-message": `CloudPage-module__PQnt3SXXqM2XW5Wl2W87`,
	"user-table": `CloudPage-module__Sp1YobfMI6WK7PEseghO`,
	"user-row": `CloudPage-module___pP8cCEsGYDmQqp0yVeK`,
	"heartbit-button": `CloudPage-module__SrVsbBbLV2sgQeo04ut7`,
	"cloud-oncall-name": `CloudPage-module___1R89zqqHCjyXRxhZoqh`,
	"block-icon": `CloudPage-module__l9wkFk4DyPEJPARkaPUQ`,
	"error-icon": `CloudPage-module__EZE65Vrgp_xaPfnHWd6Q`,
	"heart-icon": `CloudPage-module__oPfGpZf6ki1J9ZWBCgMZ`,
	"block-button": `CloudPage-module__jR6EOGKRihE26SqnzxoI`,
	"table-title": `CloudPage-module__R1JbEk62fA77lzsE40ZU`,
	"table-button": `CloudPage-module__rbtLDtgknaKSwCgru8nU`
};
export default ___CSS_LOADER_EXPORT___;
