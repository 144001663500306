// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g{width:100%;height:100%;display:flex;flex-direction:column}.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g .filter-table td{white-space:break-spaces;line-height:20px;height:auto}`, "",{"version":3,"sources":["webpack://./containers/DefaultPageLayout/DefaultPageLayout.module.scss"],"names":[],"mappings":"AAAA,gDACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAKF,iEACE,wBAAA,CACA,gBAAA,CACA,WAAA","sourcesContent":[".root {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.root :global(.filter-table) td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g`
};
export default ___CSS_LOADER_EXPORT___;
