// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Connectors-module__T3TTmkV6Zptr6TM5_KAs {
  border-radius: 2px;
  background: var(--secondary-background);
  padding: 2px 2px 2px 12px;
  flex-wrap: wrap;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./containers/AlertRules/parts/connectors/Connectors.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,yBAAyB;EACzB,eAAe;EACf,WAAW;AACb","sourcesContent":[".root {\n  border-radius: 2px;\n  background: var(--secondary-background);\n  padding: 2px 2px 2px 12px;\n  flex-wrap: wrap;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Connectors-module__T3TTmkV6Zptr6TM5_KAs`
};
export default ___CSS_LOADER_EXPORT___;
