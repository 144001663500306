// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/integration-logos.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/img/ServiceNow.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/img/PagerDuty.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/img/ElastAlert.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../assets/img/HeartBeatMonitoring.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../assets/img/grafana-legacy-alerting-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../../assets/img/grafana_icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../../assets/img/inbound-email.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegrationLogo-module__qASLBNnnIXTzY3BzAl3K {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
}

.IntegrationLogo-module__AsIcf_tqX5yPZXbMduwb {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: 100% !important;
}

.IntegrationLogo-module__Lbc5X8nSJWYGEPG0nI2z {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  background-size: 100% !important;
}

.IntegrationLogo-module__FJDByw9FKFAZ0qThiEXR {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  background-size: 100% !important;
}

.IntegrationLogo-module__Qww6BfhQeP3B7zTPktTw {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  background-size: 100% !important;
}

.IntegrationLogo-module__Oi2bpfDMaCrlfTZqNTVB {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  background-size: 100% !important;
}

.IntegrationLogo-module__ozXnoCVLlcxo_G0_mzcA {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  background-size: 100% !important;
}

.IntegrationLogo-module__O2Vr09IPaYN1yUjnxQV9 {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
  background-size: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./components/IntegrationLogo/IntegrationLogo.module.css"],"names":[],"mappings":"AAAA;EACE,mDAAuD;EACvD,4BAA4B;AAC9B;;AAEA;EACE,mDAAgD;EAChD,gCAAgC;AAClC;;AAEA;EACE,mDAA+C;EAC/C,gCAAgC;AAClC;;AAEA;EACE,mDAAgD;EAChD,gCAAgC;AAClC;;AAEA;EACE,mDAAyD;EACzD,gCAAgC;AAClC;;AAEA;EACE,mDAAkE;EAClE,gCAAgC;AAClC;;AAEA;EACE,mDAAkD;EAClD,gCAAgC;AAClC;;AAEA;EACE,mDAAmD;EACnD,gCAAgC;AAClC","sourcesContent":[".bg {\n  background: url(../../assets/img/integration-logos.png);\n  background-repeat: no-repeat;\n}\n\n.bg_ServiceNow {\n  background: url(../../assets/img/ServiceNow.png);\n  background-size: 100% !important;\n}\n\n.bg_PagerDuty {\n  background: url(../../assets/img/PagerDuty.png);\n  background-size: 100% !important;\n}\n\n.bg_ElastAlert {\n  background: url(../../assets/img/ElastAlert.svg);\n  background-size: 100% !important;\n}\n\n.bg_HeartBeatMonitoring {\n  background: url(../../assets/img/HeartBeatMonitoring.png);\n  background-size: 100% !important;\n}\n\n.bg_GrafanaLegacyAlerting {\n  background: url(../../assets/img/grafana-legacy-alerting-icon.svg);\n  background-size: 100% !important;\n}\n\n.bg_GrafanaAlerting {\n  background: url(../../assets/img/grafana_icon.svg);\n  background-size: 100% !important;\n}\n\n.bg_InboundEmail {\n  background: url(../../assets/img/inbound-email.png);\n  background-size: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `IntegrationLogo-module__qASLBNnnIXTzY3BzAl3K`,
	"bg_ServiceNow": `IntegrationLogo-module__AsIcf_tqX5yPZXbMduwb`,
	"bg_PagerDuty": `IntegrationLogo-module__Lbc5X8nSJWYGEPG0nI2z`,
	"bg_ElastAlert": `IntegrationLogo-module__FJDByw9FKFAZ0qThiEXR`,
	"bg_HeartBeatMonitoring": `IntegrationLogo-module__Qww6BfhQeP3B7zTPktTw`,
	"bg_GrafanaLegacyAlerting": `IntegrationLogo-module__Oi2bpfDMaCrlfTZqNTVB`,
	"bg_GrafanaAlerting": `IntegrationLogo-module__ozXnoCVLlcxo_G0_mzcA`,
	"bg_InboundEmail": `IntegrationLogo-module__O2Vr09IPaYN1yUjnxQV9`
};
export default ___CSS_LOADER_EXPORT___;
