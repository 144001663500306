// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alerts-module__rTHytRgBHrfciRLdZhTi{display:flex;flex-direction:column;margin-bottom:10px;gap:10px}.Alerts-module__Z1zpGLkN_3sT4c0WGaD0{padding-top:10px}.Alerts-module__rTHytRgBHrfciRLdZhTi:empty{display:none}.Alerts-module___xVqxdenXjISBUJRgW9k{margin:0}.Alerts-module__aH31bF63EX7i5A99medT{color:var(--primary-text-link)}@media(max-width: 768px){.Alerts-module__Z1zpGLkN_3sT4c0WGaD0{padding-top:50px}}`, "",{"version":3,"sources":["webpack://./containers/Alerts/Alerts.module.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAEA,qCAEE,gBAAA,CAGF,2CACE,YAAA,CAIJ,qCACE,QAAA,CAGF,qCACE,8BAAA,CAGF,yBACE,qCACE,gBAAA,CAAA","sourcesContent":[".alerts-container {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n  gap: 10px;\n\n  &--legacy {\n    // legacy navbar requires different padding-top\n    padding-top: 10px;\n  }\n\n  &:empty {\n    display: none;\n  }\n}\n\n.alert {\n  margin: 0;\n}\n\n.instructions-link {\n  color: var(--primary-text-link);\n}\n\n@media (max-width: 768px) {\n  .alerts-container--legacy {\n    padding-top: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alerts-container": `Alerts-module__rTHytRgBHrfciRLdZhTi`,
	"alerts-container--legacy": `Alerts-module__Z1zpGLkN_3sT4c0WGaD0`,
	"alert": `Alerts-module___xVqxdenXjISBUJRgW9k`,
	"instructions-link": `Alerts-module__aH31bF63EX7i5A99medT`
};
export default ___CSS_LOADER_EXPORT___;
