// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TelegramSettings-module__UscmSbV027iVu9OmGiXS {
  display: block;
}

.TelegramSettings-module__pbrWKl70UvugMpilS3Ob {
  display: flex;
  justify-content: space-between;
}

.TelegramSettings-module__qc8DWAd4UF0pg31M0upo {
  text-align: center;
  width: 725px;
}

.TelegramSettings-module__XdeKRN9jKRHVPWmVSBV3 > ul {
  margin: 20px 30px;
}

.TelegramSettings-module__DNMpjzd5tDe6x5LjGJlV {
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 24px;
}

.TelegramSettings-module__hS0ScIQLHM707Alo37bV {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./pages/settings/tabs/ChatOps/tabs/TelegramSettings/TelegramSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: block;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.telegram-infoblock {\n  text-align: center;\n  width: 725px;\n}\n\n.features-list > ul {\n  margin: 20px 30px;\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n  margin-top: 24px;\n}\n\n.infoblock-icon {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TelegramSettings-module__UscmSbV027iVu9OmGiXS`,
	"header": `TelegramSettings-module__pbrWKl70UvugMpilS3Ob`,
	"telegram-infoblock": `TelegramSettings-module__qc8DWAd4UF0pg31M0upo`,
	"features-list": `TelegramSettings-module__XdeKRN9jKRHVPWmVSBV3`,
	"infoblock-text": `TelegramSettings-module__DNMpjzd5tDe6x5LjGJlV`,
	"infoblock-icon": `TelegramSettings-module__hS0ScIQLHM707Alo37bV`
};
export default ___CSS_LOADER_EXPORT___;
