// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatOps-module__LEteOpEsUYkIGmVWmWAB {
  display: flex;
}

.ChatOps-module__lzrq6z7WKpTEAHO8XgWj {
  width: 250px;
}

.ChatOps-module__ZGhyXl4SefOLpFHbHSt8 {
  flex-grow: 1;
  padding-left: 20px;
  border-left: var(--border);
}
`, "",{"version":3,"sources":["webpack://./pages/settings/tabs/ChatOps/ChatOps.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;AAC5B","sourcesContent":[".root {\n  display: flex;\n}\n\n.tabs {\n  width: 250px;\n}\n\n.content {\n  flex-grow: 1;\n  padding-left: 20px;\n  border-left: var(--border);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ChatOps-module__LEteOpEsUYkIGmVWmWAB`,
	"tabs": `ChatOps-module__lzrq6z7WKpTEAHO8XgWj`,
	"content": `ChatOps-module__ZGhyXl4SefOLpFHbHSt8`
};
export default ___CSS_LOADER_EXPORT___;
