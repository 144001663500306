// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GSelect-module__rEaTz9bGjE5hS2j6C3A8{min-width:200px}.GSelect-module__rEaTz9bGjE5hS2j6C3A8>div{min-width:200px}`, "",{"version":3,"sources":["webpack://./containers/GSelect/GSelect.module.scss"],"names":[],"mappings":"AAAA,sCACE,eAAA,CAEA,0CAEE,eAAA","sourcesContent":[".root {\n  min-width: 200px;\n\n  & > div {\n    // If not set then inner div will not benefit of min-width\n    min-width: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GSelect-module__rEaTz9bGjE5hS2j6C3A8`
};
export default ___CSS_LOADER_EXPORT___;
