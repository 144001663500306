// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DisconnectButton-module__T3peR1pNkHHIna_1XYtU{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./containers/MobileAppConnection/parts/DisconnectButton/DisconnectButton.module.scss"],"names":[],"mappings":"AAAA,+CACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".disconnect-button {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disconnect-button": `DisconnectButton-module__T3peR1pNkHHIna_1XYtU`
};
export default ___CSS_LOADER_EXPORT___;
